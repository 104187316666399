import { useEffect, useState } from 'react';
import { MediaVariant, StatsBarProps } from '@decub8/ui';
import { commify } from '@ethersproject/units';

import { api_client } from '@src/bootstrap';
import { STATISTICS } from '@src/services/statistics';
import { EventType, StatisticType } from '@src/ts/constants';
import { EventStatistic, EventStatistics } from '@src/ts/types';

const formatValue = (value: number, type: StatisticType): string => {
    if (!value) return '0';
    switch (type) {
        case StatisticType.AverageATH:
            return commify(value).toString() + '%';
        case StatisticType.TotalAmountRaised:
            return '$' + commify(value).toString();
        case StatisticType.ATHValueDistributed:
            return '$' + commify(value).toString();
        default:
            return commify(value).toString();
    }
};

export const useEventStatistics = (nav: EventType): StatsBarProps => {
    const [eventStatistics, setEventStatistics] =
        useState<EventStatistics | null>(null);
    const [formEntriesIDO, setFormEntriesIDO] = useState<StatsBarProps>();
    const [formEntriesL2E, setFormEntriesL2E] = useState<StatsBarProps>();

    useEffect(() => {
        const fetchEventStatistics = async () => {
            const data = await api_client.query<{
                retrieveStatistics: EventStatistic[];
            }>({
                query: STATISTICS.GET_EVENT_STATISTICS,
            });
            if (!data) return;
            data.retrieveStatistics.map((statistic) => {
                setEventStatistics((prev) => ({
                    ...prev,
                    [statistic.id]: parseInt(statistic.value),
                }));
            });
        };

        fetchEventStatistics();
    }, []);

    useEffect(() => {
        if (eventStatistics) {
            const formEntriesIDO: StatsBarProps = {
                data: [
                    {
                        label: 'Unique Participants',
                        value: formatValue(
                            eventStatistics['UniqueParticipantsIDO'],
                            StatisticType.UniqueParticipantsIDO,
                        ),
                        logo: 'participants' as MediaVariant,
                    },
                    {
                        label: 'Total Amount Raised',
                        value: formatValue(
                            eventStatistics['TotalAmountRaised'],
                            StatisticType.TotalAmountRaised,
                        ),
                        logo: 'diamond' as MediaVariant,
                    },
                    {
                        label: 'Average ATH',
                        value: formatValue(
                            eventStatistics['AverageATH'],
                            StatisticType.AverageATH,
                        ),
                        logo: 'rocket' as MediaVariant,
                    },
                    {
                        label: 'Events Completed',
                        value: formatValue(
                            eventStatistics['EventsCompleteIDO'],
                            StatisticType.EventsCompleteIDO,
                        ),
                        logo: 'union' as MediaVariant,
                    },
                ],
            };
            setFormEntriesIDO(formEntriesIDO);

            const formEntriesL2E: StatsBarProps = {
                data: [
                    {
                        label: 'Unique Participants',
                        value: formatValue(
                            eventStatistics['UniqueParticipantsL2E'],
                            StatisticType.UniqueParticipantsL2E,
                        ),
                        logo: 'participants' as MediaVariant,
                    },
                    {
                        label: 'ATH distributed value',
                        value: formatValue(
                            eventStatistics['ATHValueDistributed'],
                            StatisticType.ATHValueDistributed,
                        ),
                        logo: 'diamond' as MediaVariant,
                    },
                    {
                        label: 'Events Completed',
                        value: formatValue(
                            eventStatistics['EventsCompleteL2E'],
                            StatisticType.EventsCompleteL2E,
                        ),
                        logo: 'union' as MediaVariant,
                    },
                    {
                        label: 'Tasks Completed',
                        value: formatValue(
                            eventStatistics['TasksCompleted'],
                            StatisticType.TasksCompleted,
                        ),
                        logo: 'union' as MediaVariant,
                    },
                ],
            };

            setFormEntriesL2E(formEntriesL2E);
        }
    }, [eventStatistics]);

    switch (nav) {
        case EventType.Crowdfunding:
            return formEntriesIDO;
        case EventType.TokenClaim:
            return formEntriesL2E;
        default:
            return formEntriesIDO;
    }
};
