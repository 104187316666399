import React from 'react';
import { StatsBar } from '@decub8/ui';

import { useEventStatistics } from '@src/hooks/useEventStatistics';
import { EventType } from '@src/ts/constants';

export const StatisticsContainer: React.FC<{
    eventType: EventType;
}> = ({ eventType }) => {
    const props = useEventStatistics(eventType);

    // props with out entries that have a value of "0"
    const filteredProps = {
        ...props,
        data: props?.data?.filter(
            (entry) => entry.value.startsWith('0') === false,
        ),
    };

    return <StatsBar {...filteredProps} className="mt-12" />;
};
