import { gql } from '@apollo/client';

export const STATISTICS = {
    GET_EVENT_STATISTICS: gql`
        query RetrieveStatistics {
            retrieveStatistics {
                value
                id
            }
        }
    `,
};
