import { ProjectCardProps } from '@decub8/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { BASE_IMAGE_URL, CONTENT, NETWORKS } from '@src/config';
import { EventType } from '@src/ts/constants';
import { DisplayProject } from '@src/ts/interfaces';
import { EventChipStatus } from '@src/ts/types';

dayjs.extend(utc);

const BADGE_COLOR = {
    [EventChipStatus.NowLive]: 'accent',
    [EventChipStatus.WhitelistOpen]: 'purple',
    [EventChipStatus.WhitelistClosed]: 'orange',
    [EventChipStatus.YouAreWhitelisted]: 'green',
    [EventChipStatus.ComingSoon]: 'yellow',
};

export interface TempProjectCardProps extends ProjectCardProps {
    chainId?: number;
}

export enum ChipType {
    Private = 'Private',
    Seed = 'Seed',
    Public = 'Public',
    TokenClaim = 'TokenClaim',
    Standard = 'Standard',
    Exclusive = 'Exclusive',
    Acceleration = 'Acceleration',
    Incubation = 'Incubation',
}

// The hook that returns the props for the ProjectCard component, the Projectcards
// are the cards that are displayed on the home page
export const useProjectCard = (args: DisplayProject): TempProjectCardProps => {
    const { name, hero, square_logo, announced, teaser, id, events, token } =
        args;

    const image_url = `${BASE_IMAGE_URL}/${id}/${announced ? hero : teaser}`;

    // now only have one event in a single project because they are mapped that way to display a card for every event
    const [event] = events;

    const logo_url = announced
        ? `${BASE_IMAGE_URL}/${id}/${square_logo}`
        : CONTENT.notAnnouncedSquareLogo || '';

    const { network } = token || {};

    const is_crowdfunding = event?.type === EventType.Crowdfunding;
    const chip_type = is_crowdfunding
        ? ChipType[event?.crowdfunding_type]
        : ChipType[event?.type];

    // attempt to find a network in the NETWORKS object with a matching 'identifier' to get the network_logo
    const network_logo =
        !announced || !network
            ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/network-logos/unknown_logo_light.svg'
            : Object.values(NETWORKS).find(
                  (_network) => _network.identifier === network,
              )?.network_logo;

    const event_d =
        event &&
        !event?.is_hidden &&
        dayjs(Number(event?.start_date) * 1000)
            .utc()
            .format('DD MMM YY / HH:mm UTC');

    return {
        chainId: event?.chainId,
        status_badge: args.event_status,
        logo_url,
        // if chip type is undefined it will display 'TBA', chip labels stores in UI library
        chip_text: announced && event ? chip_type : undefined,
        heading_text: !announced ? 'Coming soon' : name,
        card_data: [
            {
                label: `Event date`,
                values: [
                    {
                        value: (announced && event_d) || 'TBA',
                    },
                ],
            },
            {
                label: 'Total allocation',
                values: [
                    {
                        value:
                            (event?.total_allocation &&
                                (event?.type === EventType.TokenClaim
                                    ? event?.total_allocation
                                    : event?.total_allocation
                                    ? `${event?.total_allocation} USD`
                                    : '')) ||
                            'TBA',
                    },
                ],
            },
            {
                label: 'Event token price',

                values: [
                    {
                        value:
                            (announced &&
                                (event?.type === EventType.TokenClaim
                                    ? 'Live'
                                    : event?.token_price
                                    ? `${event?.token_price} USD`
                                    : '')) ||
                            'TBA',
                    },
                ],
            },
        ].filter((obj) => {
            return !(
                (obj.label === 'Event date' ||
                    obj.label === 'Event token price') &&
                !announced
            );
        }),
        image_url,
        badge_color: BADGE_COLOR[args.event_status],
        className: '',
        network_logo,
        protected_img_url:
            announced && event?.refund_deadline
                ? 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg'
                : '',
        protected_helper_text:
            'Refund your investment within a certain period after the event is complete.',
        has_cut_corners: CONTENT.hasCutProjectCards,
        announced,
    };
};
