import { useEffect, useMemo, useState } from 'react';
import {
    Carousel,
    Dropdown,
    Tabs,
    Typography,
    useBreakpoint,
} from '@decub8/ui';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

import { Content, ContentSection } from '@src/components';
import ProjectCard from '@src/components/Project/ProjectCard';
import { StatisticsContainer } from '@src/components/StatisticsContainer';
import { useAppSelector } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { useLoadHome } from '@src/hooks/useLoadHome';
import { EventType, ProjectStatus, UserGroup } from '@src/ts/constants';
import {
    getActiveIndex,
    getDateToUse,
    getEventStatus,
} from '@src/utils/getters';

const ProjectList: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    return (
        <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-12 items-stretch"
            data-testid="event-container"
        >
            {children}
        </div>
    );
};

const options = [
    { title: 'IDO events', id: EventType.Crowdfunding as string },
    { title: 'Learn to earn events', id: EventType.TokenClaim as string },
];

enum SortOrder {
    Newest = 'Newest',
    Oldest = 'Oldest',
}

const Home: React.FC = () => {
    const [nav, setNav] = useState(options[0].id);
    const [sort_order, setSortOrder] = useState(SortOrder.Newest);
    const {
        featured_items,
        landing_page_video,
        partner_section,
        mapped_projects,
        video_loaded,
        partner_section_loaded,
    } = useLoadHome();
    const [projects, setProjects] = useState(mapped_projects);

    const router = useRouter();
    const { user } = useAppSelector((state) => state.auth);
    const { contract_manager } = useGlobalContext();

    const breakpoint = useBreakpoint();

    useEffect(() => {
        setProjects(mapped_projects);
    }, [mapped_projects]);

    const { crowdfunding_events, token_claim_events } = useMemo(() => {
        const crowdfunding_events = [];
        const token_claim_events = [];

        projects?.forEach((project) => {
            const [event] = project.events;
            if (event.type === EventType.Crowdfunding) {
                crowdfunding_events.push(project);
            } else if (event.type === EventType.TokenClaim) {
                token_claim_events.push(project);
            }
        });

        return { crowdfunding_events, token_claim_events };
    }, [mapped_projects, projects]);

    // Determine which array of projects to use based on the selected tab
    const events_to_display =
        nav === EventType.Crowdfunding
            ? crowdfunding_events
            : token_claim_events;

    const sorted_events = useMemo(() => {
        const sorted_by_status_and_date = events_to_display
            .filter(
                (project) =>
                    UserGroup.ProjectManager.includes(user?.role) ||
                    project.status === ProjectStatus.ACTIVE,
            )
            .sort((a, b) => {
                // First, sort by status priority
                const status_diff = getActiveIndex(a) - getActiveIndex(b);

                if (status_diff !== 0) return status_diff;

                // Then sort by the latest start date from events if status is the same
                const date_A = getDateToUse(a);
                const date_B = getDateToUse(b);

                return sort_order === SortOrder.Newest
                    ? date_B - date_A
                    : date_A - date_B;
            });

        return sorted_by_status_and_date;
    }, [user, events_to_display, sort_order, projects]);

    const sort_options = [
        {
            name: 'Newest',
            onOptionClick: () => setSortOrder(SortOrder.Newest),
        },
        {
            name: 'Oldest',
            onOptionClick: () => setSortOrder(SortOrder.Oldest),
        },
    ];

    useEffect(() => {
        if (!user) return;

        // Get the event status for each project - used for sorting events & giving user feedback on the status of the event
        Promise.all(
            mapped_projects.map(async (p) => {
                const {
                    events: [event],
                } = p;

                const event_status = await getEventStatus(
                    event,
                    p.announced,
                    contract_manager,
                    user.id,
                );

                return {
                    ...p,
                    event_status,
                };
            }),
        )
            .then((projects) => {
                setProjects(projects);
            })
            .catch((err) =>
                console.error('Error while getting updated project chips', err),
            );
    }, [contract_manager, user, mapped_projects]);

    return (
        <Content padding={false}>
            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
            >
                {!landing_page_video ||
                    (!landing_page_video?.hidden && (
                        <ContentSection section={landing_page_video} />
                    ))}
            </motion.section>
            <motion.section
                initial={{ opacity: 0 }}
                animate={
                    video_loaded && partner_section_loaded ? { opacity: 1 } : {}
                }
                transition={{ duration: 0.7 }}
            >
                <div className="px-5">
                    {!partner_section ||
                        (!partner_section.hidden && (
                            <ContentSection
                                section={partner_section}
                                className="my-[60px]"
                            />
                        ))}

                    <Carousel
                        items={featured_items
                            .map((i) => ({
                                ...i,
                                handleClick: () =>
                                    i.link.includes('http')
                                        ? window.open(i.link)
                                        : router.push(i.link),
                            }))
                            .filter(
                                (item) =>
                                    item.title !== '' && item.subtitle !== '',
                            )}
                        className={`mt-5 mb-16 h-full ${
                            !partner_section?.hidden ? '' : 'mt-8'
                        }`}
                    />
                    {/* ELEMENT USED FOR SCROLLING TO - DON'T DELETE */}
                    <div id="events"></div>
                    <Tabs
                        className="max-w-[380px] mt-8"
                        options={options}
                        current={nav}
                        setCurrent={setNav}
                    />
                    <StatisticsContainer eventType={nav as EventType} />
                    <div className="flex items-center justify-between mt-8 mb-5">
                        <Typography size="3xl" allBold>
                            {options.find((option) => option.id === nav).title}
                        </Typography>

                        <div className="flex items-center space-x-5 justify-between">
                            {breakpoint > 450 && (
                                <Typography>Sort by</Typography>
                            )}
                            <Dropdown
                                accent_icon
                                id="project-page-sort"
                                title={
                                    <span className="mx-3">{sort_order}</span>
                                }
                                options={sort_options}
                                container_class="z-30"
                                dropdown_width=""
                            />
                        </div>
                    </div>
                    <ProjectList>
                        {sorted_events.map((p, idx) => (
                            <ProjectCard
                                key={`${p.id}-${idx}-${p.events[0]?.name}`}
                                {...p}
                            />
                        ))}
                    </ProjectList>
                </div>
            </motion.section>
        </Content>
    );
};

export default Home;
