import { ProjectCard as ProjectCardUI } from '@decub8/ui';
import Link from 'next/link';

import { CONTENT } from '@src/config';
import { DisplayProject } from '@src/ts/interfaces';
import { generateSlug } from '@src/utils/format';

import { useProjectCard } from './hooks';

const ProjectCard: React.FC<DisplayProject> = (args) => {
    const {
        announced,
        slug,
        events: [event],
    } = args;
    const props = useProjectCard(args);
    const event_slug = generateSlug(event?.is_hidden ? '' : event?.name || '');

    return (
        <Link
            href={
                announced
                    ? `/project/${slug}/${event_slug}`
                    : CONTENT.telegramUrl
            }
            className="mx-auto w-full"
            target={announced ? '_self' : '_blank'}
            rel="noopener noreferrer"
        >
            <ProjectCardUI {...props} />
        </Link>
    );
};

export default ProjectCard;
