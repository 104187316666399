import { useCallback, useEffect, useState } from 'react';
import { FeaturedItem } from '@decub8/ui';

import { api_client } from '@src/bootstrap/index';
import { createContractManager } from '@src/contracts/index';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import { ContentSectionType } from '@src/ts/constants';
import { DisplayProject, IContentSection } from '@src/ts/interfaces';
import { getEventStatus } from '@src/utils/getters';

export const useLoadHome = () => {
    const [mapped_projects, setMappedProjects] = useState<DisplayProject[]>([]);
    const [featured_items, setFeaturedItems] = useState<FeaturedItem[]>([]);
    const [landing_page_video, setLandingPageVideo] =
        useState<IContentSection | null>(null);
    const [partner_section, setPartnerSection] =
        useState<IContentSection | null>(null);
    const [partner_section_loaded, setPartnerSectionLoaded] = useState(false);
    const [video_loaded, setVideoLoaded] = useState(false);

    // get all the projects and events
    const loadProjects = useCallback(async () => {
        const contract_manager = createContractManager();

        try {
            const [
                {
                    retrieveProjects: { data: projects },
                },
            ] = await Promise.all([
                api_client.query<{
                    retrieveProjects: { data: DisplayProject[] };
                }>({
                    query: PROJECT.GET_LIST,
                    variables: { limit: 100 },
                    fetchPolicy: 'network-only',
                }),
            ]);

            const reducedProjects = projects.reduce((acc, p) => {
                if (p.events?.length) {
                    const projectEvents = [];
                    for (const e of p.events) {
                        const events = [
                            {
                                ...e,
                                event_details: {
                                    ...(e.event_details || {}),
                                    durations: e.event_details?.durations || [
                                        3600 * 22,
                                        3600,
                                        3600,
                                    ],
                                },
                            },
                        ];
                        projectEvents.push({ ...p, events });
                    }
                    return [...acc, ...projectEvents];
                } else {
                    return acc;
                }
            }, []);

            const _mappedProjects = await Promise.all(
                reducedProjects.map(async (p) => {
                    const {
                        events: [event],
                    } = p;

                    const event_status = await getEventStatus(
                        event,
                        p.announced,
                        contract_manager,
                    );

                    return {
                        ...p,
                        event_status,
                    };
                }),
            );

            setMappedProjects(_mappedProjects);
        } catch (error) {
            console.error('Failed to load home data:', error);
        }
    }, []);

    // retrieve landing page video !!and this!
    const loadLandingPageVideo = useCallback(async () => {
        api_client
            .query<{ retrieveContentSection: IContentSection }>({
                query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
                fetchPolicy: 'network-only',
                variables: {
                    name: ContentSectionType.LandingPageVideo,
                },
            })
            .then(({ retrieveContentSection }) => {
                setLandingPageVideo(retrieveContentSection);
            })
            .catch(() => {
                setLandingPageVideo(null);
            });
    }, []);

    // retrieve partner section
    const loadPartnerSection = useCallback(async () => {
        api_client
            .query<{ retrieveContentSection: IContentSection }>({
                query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
                fetchPolicy: 'network-only',
                variables: {
                    name: ContentSectionType.PartnerSection,
                },
            })
            .then(({ retrieveContentSection }) => {
                setPartnerSection(retrieveContentSection);
            })
            .catch(() => {
                setPartnerSection(null);
            });
    }, []);

    // retrieve featured items
    const loadFeaturedItems = useCallback(async () => {
        api_client
            .query<{ getFeaturedItems: FeaturedItem[] }>({
                query: PROJECT.GET_FEATURED_ITEMS,
                fetchPolicy: 'network-only',
            })
            .then(({ getFeaturedItems }) => {
                setFeaturedItems(getFeaturedItems);
            })
            .catch(() => {
                setFeaturedItems([]);
            });
    }, []);

    useEffect(() => {
        setVideoLoaded(false);
        loadLandingPageVideo();
        setVideoLoaded(true);
        // console.log('video data loaded');
    }, [loadLandingPageVideo]);

    useEffect(() => {
        setPartnerSectionLoaded(false);
        loadFeaturedItems();
        setPartnerSectionLoaded(true);
        // console.log('featured items loaded');
    }, [loadFeaturedItems]);

    useEffect(() => {
        loadPartnerSection();
        // console.log('partner data loaded');
    }, [loadPartnerSection]);

    useEffect(() => {
        loadProjects();
        // console.log('project data loaded');
    }, [loadProjects]);

    return {
        mapped_projects,
        featured_items,
        landing_page_video,
        partner_section,
        partner_section_loaded,
        video_loaded,
    };
};
